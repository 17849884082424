.second-section {
  margin: 200px 20px;
  color: white;
  }
  .second-section img {
    margin-top: 600px;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .second-section {
      flex-direction: column;
    }
  
    .second-section img {
      width: 100%;
      margin-bottom: 10px; /* Adjust the margin as needed */
    }
  }