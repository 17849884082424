.new-section {
  padding: 50px 20px;
}

.content {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.text {
  width: 50%;
}

.text h2 {
  font-size: 32px;
  margin-bottom: 15px;
  color: rgb(196, 20, 20); /* Puedes ajustar el color según tus preferencias */
}

.text p {
  font-size: 16px;
  color: #ffffff; /* Puedes ajustar el color según tus preferencias */
  text-align: start;
}

.image {
  width: 30%;
}

.image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px; /* Ajusta según tus preferencias */
}

/* Estilos para el enlace de la imagen */
.image-link img {
  position: relative;
  bottom: 10px; /* Ajusta según la distancia desde abajo que desees */
  right: 10px; /* Ajusta según la distancia desde la derecha que desees */
  max-width: 100px; /* Ajusta el tamaño máximo de la imagen */
  margin-right: -300px;
  height: auto;
  animation: pulse 2s infinite; /* Usa la animación 'pulse' definida anteriormente */
}
/* Animación de spin */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .image-link img {
    position: relative;
    margin-right: 50px;
    margin-top: 20px;
  }
  .image img {
    margin-top: 50px;
  }
}
