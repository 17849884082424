@font-face {
  /* Ajusta la ruta según la ubicación de tu archivo de fuente */
  font-family: 'pixel'; /* Nombre que asignas a la fuente */
  src: url('./fonts/Minecraft.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'pixel', sans-serif; /* Ajusta el nombre de la fuente según el archivo que has proporcionado */
  background-color: grey;
  background-size: cover; /* Para cubrir toda la pantalla */
}

.App {
  text-align: center;
  cursor: url(http://www.rw-designer.com/cursor-extern.php?id=123893), auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Agrega un margen superior al primer componente */
.firstSection {
  margin-top: 100px; /* Ajusta el valor según la altura de tu header */
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
