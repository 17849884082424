.about-section {
  height: 60vh;
  background: url("../images/bg-section.png") no-repeat center center fixed;
  background-size: cover; /* Para cubrir toda la pantalla */
  z-index: 1;
  position: relative; /* Añadido: asegura que z-index tenga efecto */
  overflow: hidden; /* Evita que el contenido se desborde de la sección */
}

.about-section::before,
.about-section::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 20px; /* Altura del borde */
  background-color: #3e2723; /* Color del borde (marrón oscuro) */
}

.about-section::before {
  top: 0;
}

.about-section::after {
  bottom: 0;
}

.about-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; /* Para centrar el contenido dentro de .about-content */
}

.center-image {
  width: 300px; /* Cambia las dimensiones según sea necesario */
  height: auto; /* Mantiene la proporción de la imagen */
  transition: filter 0.3s ease-out, transform 0.3s ease-out; /* Agregado: transiciones para suavizar los cambios */
}

.center-image:hover {
  filter: brightness(1.2); /* Brillo al hacer hover */
  transform: scale(1.1); /* Escala al hacer hover */
}

/* Animación de vibración */
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

.center-image:hover {
  animation: shake 0.5s ease-in-out infinite; /* Animación de vibración al hacer hover */
}

.leaf {
  position: absolute;
  width: 100%;
  height: 50%;
  /* left:0; */
  z-index: 0;
}
.leaf div {
  position: absolute;
  display: block;
}
.leaf div:nth-child(1) {
  left: 20%;
  animation: fall 15s linear infinite;
  animation-delay: -2s;
}
.leaf div:nth-child(2) {
  left: 70%;
  animation: fall 15s linear infinite;
  animation-delay: -4s;
}
.leaf div:nth-child(3) {
  left: 10%;
  animation: fall 20s linear infinite;
  animation-delay: -7s;
}
.leaf div:nth-child(4) {
  left: 50%;
  animation: fall 18s linear infinite;
  animation-delay: -5s;
}
.leaf div:nth-child(5) {
  left: 85%;
  animation: fall 14s linear infinite;
  animation-delay: -5s;
}
.leaf div:nth-child(6) {
  left: 15%;
  animation: fall 16s linear infinite;
  animation-delay: -10s;
}
.leaf div:nth-child(7) {
  left: 90%;
  animation: fall 15s linear infinite;
  animation-delay: -4s;
}

@keyframes fall {
  0% {
    opacity: 1;
    top: -10%;
    transform: translateX (20px) rotate(0deg);
  }
  20% {
    opacity: 0.8;
    transform: translateX (-20px) rotate(45deg);
  }
  40% {
    transform: translateX (-20px) rotate(90deg);
  }
  60% {
    transform: translateX (-20px) rotate(135deg);
  }
  80% {
    transform: translateX (-20px) rotate(180deg);
  }
  100% {
    top: 110%;
    transform: translateX (-20px) rotate(225deg);
  }
}
.leaf1 {
  transform: rotateX(180deg);
}
